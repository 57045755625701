"use client";

import Link from "next/link";
import { useState } from "react";
import Image from "next/image";
import styles from "./Parallax.module.css";
import ParallaxParticle from "./ParallaxParticle";

export default function Parallax() {
  const [showBanner, setShowBanner] = useState(true);

  // Function to close the banner
  const closeBanner = () => {
    setShowBanner(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.background}></div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      >
        <ParallaxParticle className={styles.particleLanding} />
      </div>

      {showBanner && (
        <div className={styles.blogBanner}>
          <button className={styles.closeButton} onClick={closeBanner}>
            X
          </button>
          <Link href="/blog" passHref>
            <div className={styles.bannerContent}>
              <h2>Check Out My Latest Blog Posts!</h2>
              <p>
                Stay updated with the latest trends in tech and development.
              </p>
            </div>
          </Link>
        </div>
      )}

      <div className={styles.content}>
        <p className={styles.text2}>
          Transforming Experience into Opportunities with Strategic Insight and
          Creative Foresight
        </p>
      </div>

      <a
        className={styles.linkedIn}
        href="https://www.linkedin.com/in/-jessica-cousins"
        target="_blank"
        rel="noopener noreferrer"
      >
        LinkedIn
      </a>
      <a
        className={styles.email}
        href="mailto:jcousins87@email.com"
        target="_blank"
        rel="nopener noreferrer"
      >
        Email
      </a>
    </div>
  );
}
